
:root {
  --base-unit: 0.1vmin;
}

@media (min-width: 700px) and (min-height: 700px) {
  :root {
    --base-unit: 0.7px;
  }
}

@media (max-width: 300px), (max-height: 300px) {
  :root {
    --base-unit: 0.3px;
  }
}

:root {
  --body-margin: calc(40 * var(--base-unit));
  --body-background-color: rgb(96%, 96%, 96%);
  --players-background-color: rgb(96%, 96%, 96%);
  --header-color: rgb(67%, 67%, 67%);
  --title-color: rgb(87.5%, 87.5%, 87.5%);
  --group-background-color: rgba(100%, 100%, 100%, 90%);
  --button-active-color: rgba(90%, 90%, 90%, 90%);
  --button-border-color: rgb(75%, 75%, 75%);
  --button-focus-color: rgb(80%, 80%, 80%);
  --button-focus-width: calc(5 * var(--base-unit));
  --group-circle-size: calc(140 * var(--base-unit));
  --group-gap: calc(40 * var(--base-unit));
  --group-title-gap: 0.5em;
  --clip-circle-size: calc(45 * var(--base-unit));
  --clip-vertical-gap: calc(16 * var(--base-unit));
  --clip-horizontal-gap: calc(8 * var(--base-unit));
  --clip-title-gap: 0.75em;
}

body {
  margin: var(--body-margin);
  font-family: Lato;
  background-color: var(--body-background-color);
}

button,
#infoButton {
  background-color: transparent;
  margin: 0;
  padding: 0;
  border: none;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  text-transform: none;
  line-height: normal;
  cursor: pointer;
}

button:focus,
#infoButton:focus {
  outline: var(--button-focus-width) dotted var(--button-focus-color);
}

a#infoButton {
  text-decoration: none;
}

#header {
  position: absolute;
  top: 0;
  left: var(--body-margin);
  right: var(--body-margin);
  height: var(--group-circle-size);
  color: var(--header-color);
}

@media (min-width: 50em) {
  #header {
    margin-left: calc((100% - 50em) * 0.2);
  }
}

#header > * {
  position: absolute;
  text-transform: lowercase;
  font-size: calc(var(--group-circle-size) * 0.3);
}

#title {
  bottom: 0;
  line-height: 0.8;
  left: 1.25em;
  color: var(--title-color);
  font-size: calc(var(--group-circle-size) * 0.7);
  font-weight: bold;
}

#composer {
  top: 0.333em;
  left: 0.5em;
  font-size: calc(var(--group-circle-size) * 0.25);
}

#captionAndInfo {
  left: 12.25em;
  line-height: 0.8;
  bottom: 0;
  right: calc(var(--group-circle-size) + var(--body-margin));
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

#infoButton {
  color: inherit;
  font-size: calc(var(--group-circle-size) * 0.2);
  margin-left: 1em;
  padding: 0.1ex 0.5ex;
  font-weight: bold;
  border-radius: 0;
  border: 2px solid var(--button-border-color);
  background-color: var(--group-background-color);
  cursor: pointer;
}

#infoButton.active {
  background-color: var(--button-active-color);
}

ol, ul, li {
  list-style-type: none;
  margin: 0;
  padding: 0;
  padding-inline-start: 0;
  margin-block-start: 0;
  margin-block-end: 0;
}

#field {
  position: absolute;
  left: calc(var(--group-circle-size) + var(--body-margin));
  right: calc(var(--group-circle-size) + var(--body-margin));
  top: calc(var(--group-circle-size));
  bottom: 0;
  margin: var(--body-margin);
  background-color: var(--players-background-color);
}

#players {
  width: 100%;
  height: 100%;
  display: inline-flex;
  flex-wrap: wrap;
  gap: 0;
  justify-content: center;
  align-content: flex-start;
  transition: opacity 0.5s;
}

.groups {
  z-index: 1;
  position: absolute;
  top: calc(var(--group-circle-size));
  display: inline-block;
  white-space: nowrap;
}

.groups.left {
  left: 0;
}

.groups.right {
  right: 0;
}

.group {
  display: flex;
  align-items: flex-start;
  position: absolute;
}

.left .group {
  flex-direction: row;
  text-align: left;
  left: 0;
}

.right .group {
  flex-direction: row-reverse;
  text-align: right;
  right: 0;
}

.left .flyout .groupTitle,
.left .flyout .clipTitle {
  flex-direction: row;
}

.right .groupTitle,
.right .clipTitle {
  flex-direction: row-reverse;
}

.groupTitle,
.clips {
  padding: var(--body-margin);
}

.groupTitle .groupButton {
  height: var(--group-circle-size);
  width: var(--group-circle-size);
  position: relative;
}

.groupTitle .groupButton img,
.groupTitle .groupButton .overlay {
  position: absolute;
}

.groupTitle .groupButton img {
  height: calc(var(--group-circle-size) * 0.95);
  width: calc(var(--group-circle-size) * 0.95);
  top: calc(var(--group-circle-size) * 0.025);
  right: calc(var(--group-circle-size) * 0.025);
  transition: opacity 1s;
}

.buttonLoading .groupTitle .groupButton img {
  opacity: 0;
}

.groupTitle .groupButton .overlay {
  top: 0;
  left: 0;
  height: var(--group-circle-size);
  width: var(--group-circle-size);
  background-repeat: no-repeat;
  background-position: center;
  background-size: var(--group-circle-size);
}

.groupTitle.text {
  font-weight: bold;
  font-size: calc(var(--group-circle-size) * 0.4);
  display: inline-flex;
  align-items: center;
}

.flyout {
  position: absolute;
}

.left .flyout {
  left: calc(var(--group-circle-size) + 2 * var(--body-margin));
}

.right .flyout {
  right: calc(var(--group-circle-size) + 2 * var(--body-margin));
}

.clips {
  padding-top: 0;
  padding-bottom: var(--clip-vertical-gap);
}

.clip {
  margin-top: var(--clip-vertical-gap);
}

.clip:first-child {
  margin-top: 0;
}

.clip.active {
  font-weight: bold;
}

.clipTitle {
  width: 100%;
  display: inline-flex;
  align-items: center;
  gap: 0 var(--clip-title-gap);
}

.clipTitle .text {
  font-size: calc(var(--clip-circle-size) * 0.7);
}

.right .clip {
  text-align: right;
}

.clipTitle .state {
  height: var(--clip-circle-size);
  width: var(--clip-circle-size);
  background-repeat: no-repeat;
  background-position: center;
  background-size: var(--clip-circle-size);
}

video {
  background-color: var(--players-background-color);
  pointer-events: none;
}

#players .vjs-modal-dialog .vjs-modal-dialog-content {
  z-index: unset;
}

.groupTitle,
.clip {
  cursor: pointer;
}

.flyout {
  visibility: hidden;
}

.group:is(.hover, :focus-within) > .groupTitle,
.group:is(.hover, :focus-within) .flyout {
  background-color: var(--group-background-color);
}

.group:is(.hover, :focus-within) > .groupTitle,
.group:is(.hover, :focus-within) .flyout {
  z-index: 2;
}

.group:is(.hover, :focus-within) .flyout {
  visibility: visible;
}

#info {
  position: absolute;
  width: 100%;
  height: 100%;
  border: none;
  opacity: 0%;
  transition: opacity 0.5s;
  display: none;
}

#field.show-info #info {
  display: unset;
}

#field.show-info #players {
  display: none;
}

#info.fade {
  opacity: 100%;
}

#players.fade {
  opacity: 0;
}

#iOS-unmute {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  justify-content: center;
  align-items: center;
  background-color: rgba(87.5%, 87.5%, 87.5%, 50%);
  backdrop-filter: blur(3px);
  font-size: 200%;
}

#iOS-unmute.active {
  display: flex;
}

#iOS-unmute button {
  background-color: var(--button-active-color);
  border: 0.1em solid var(--button-border-color);
  padding: 0.5em;
}

#log {
  position: absolute;
  top: 0;
  left: 0;
  width: 20em;
  height: calc(var(--group-circle-size) + var(--body-margin));
  background-color: white;
  display: none;
  overflow-y: scroll;
}

#log p {
  margin: 0.5em 0;
}

#log.active {
  display: block;
}
